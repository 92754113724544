/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        jQuery(function($) {
          $("#menu-btn-mobile").click(function() {
            $(this).toggleClass("highlight");
          });

            $('.toggle-search').on('click', function(event) {
            event.preventDefault();
            $('.search-nav').toggleClass('open');
            $('.toggle-search').toggleClass('open');
          });

          $(document).ready(function() {
            $('.equal-heights').matchHeight();
          });
        });
      },

      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },

    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        jQuery(function($) {
          $(document).ready(function(){

            $('.carousel-hero').owlCarousel({
              autoplay: true,
              loop: true,
              margin: 0,
              nav: true,
              navText: [
                "<i class='fa fa-2x fa-angle-left'></i>",
                "<i class='fa fa-2x fa-angle-right'></i>"
              ],
              items: 1
            });

            $('.carousel-posts').owlCarousel({
              loop: true,
              nav: false,
              dots: true,
              margin: 15,
              responsive : {
                0 : {
                  items: 1,
                  slideBy: 1
                },
                480 : {
                  items: 2,
                  slideBy: 2
                },
                992 : {
                  items: 3,
                  slideBy: 3
                }
              }
            });
          });
        });
      },

      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    'contact': {
      init: function() {
        // Google Map
        var lat = coords.lat;
        var long = coords.long;

        jQuery(function($) {

          $(document).ready(function() {

                if ($('#map-canvas').length > 0) {
                var isDraggable = $(document).width() > 768 ? true : false;

                  var styles = [
                      {
                          featureType: "landscape",
                          stylers: [
                              { saturation: -100 },
                              { lightness: 65 },
                              { visibility: "on" }
                          ]
                      },{
                          featureType: "poi",
                          stylers: [
                              { saturation: -100 },
                              { lightness: 51 },
                              { visibility: "simplified" }
                          ]
                      },{
                          featureType: "road.highway",
                          stylers: [
                              { saturation: -100 },
                              { visibility: "simplified" }
                          ]
                      },{
                          featureType: "road.arterial",
                          stylers: [
                              { saturation: -100 },
                              { lightness: 30 },
                              { visibility: "on" }
                          ]
                      },{
                          featureType: "road.local",
                          stylers: [
                              { saturation: -100 },
                              { lightness: 40 },
                              { visibility: "on" }
                          ]
                      },{
                          featureType: "transit",
                          stylers: [
                              { saturation: -100 },
                              { visibility: "simplified" }
                          ]
                      },{
                          featureType: "administrative.province",
                          stylers: [
                              { visibility: "off" }
                          ]
                      },{
                          featureType: "water",
                          elementType: "labels",
                          stylers: [
                              { visibility: "on" },
                              { lightness: -25 },
                              { saturation: -100 }
                          ]
                      },{
                          featureType: "water",
                          elementType: "geometry",
                          stylers: [
                              { hue: "#ffff00" },
                              { lightness: -25 },
                              { saturation: -97 }
                          ]
                      }
                  ];

                var mapOptions = {
                    styles: styles,
                    scrollwheel: false,
                    zoom: 16,
                    center: new google.maps.LatLng(lat, long),
                    zoomControl: true,
                    draggable: isDraggable
                };

                var map = new google.maps.Map(document.getElementById('map-canvas'),mapOptions);

                var image = {
                    url: template_path + '/dist/images/marker.png',
                    size: new google.maps.Size(94, 106),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(47, 106)
                };

                new google.maps.Marker({
                    position: new google.maps.LatLng(lat, long),
                    map: map,
                    draggable: false,
                    icon: image
                });
                }
          });
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
